import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Maya`}</h2>
    <p>{`Been power user of ChatGPT
Accelerated learning
Language translation tool
Felt limited by the interface, developing slow
Single response seems to stunt the discussion`}</p>
    <p>{`Building for myself as the user`}</p>
    <p>{`What does it look like to have a more well-rounded world view?
More perspectives - break out of the confirmation bias current information internet is built on
More human knowledge that isn't heavily indexed on the web`}</p>
    <ul>
      <li parentName="ul">{`Annotations, like wikipedia or Quora
How do we rank cutting edge knowledge?`}</li>
      <li parentName="ul">{`Community notes`}</li>
    </ul>
    <p>{`Called Maya
"the illusion of reality"`}</p>
    <p>{`Keep a pure relationship between app and user, no third party incentive (advertiser, shareholder, donor)`}</p>
    <ul>
      <li parentName="ul">{`Initial UX benefit to user seems to decay to the third stakeholder`}</li>
    </ul>
    <p>{`Build for long term, scale, and sustainability`}</p>
    <ul>
      <li parentName="ul">{`Build patiently, but remain ambitious`}</li>
      <li parentName="ul">{`Generate revenue ahead of spend`}</li>
      <li parentName="ul">{`Organic / WoM growth only`}</li>
      <li parentName="ul">{`Work with trusted friends, high integrity`}</li>
    </ul>
    <p>{`Download the beta, provide feedback
Contribute annotations (coming soon)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      